<template>
    <a v-if="aid == 3007 && newData.resourceUrl" :href="newData.clickUrl" target="_blank">
        <img :src="newData.resourceUrl" alt="" class="img-new" />
    </a>
    <div v-else :id="'ifx_' + aid" :style="styleContent"></div>
</template>

<script>
import _ from 'lodash';

export default {
    name: 'ifx',
    props: {
        aid: {
            required: true,
            type: [Number, String]
        },
        textStyle: {
            type: String
        }
    },
    data() {
        return {
            pageName: 'ifx组件',
            styleContent: '',
            newData: {
                resourceUrl: '',
                clickUrl: ''
            }
        };
    },
    mounted() {
        this.$nextTick(() => {
            if (this.aid == 3007) {
                this.getIfNewData();
            } else {
                this.getIfxData();
            }
        });
    },
    methods: {
        getIfxData() {
            let url = `https://ifx.anjuke.com/s?o=3&st=ajk&c=11&p=${this.aid}`;
            let linkClass = 'gifx';
            let targetNode = `ifx_${this.aid}`;
            let self = this;
            $.ajax({
                url,
                type: 'get',
                dataType: 'jsonp',
                success: function(data) {
                    let arr = (data && data.advInfoList) || [];
                    if (!_.isArray(arr) || arr.length === 0) {
                        return;
                    }
                    $.each(arr, function(index, val) {
                        let link = $('<a/>'),
                            img = new Image();
                        img.onload = function() {
                            link.addClass(linkClass);
                            link.attr('href', val.clickUrl);
                            link.append($(img));
                            $('#' + targetNode).append(link);
                            self.styleContent = self.textStyle;
                            $(document).trigger('ifxLoad', [val]);
                        };
                        img.src = val.resourceUrl;
                    });
                }
            });
        },
        getIfNewData() {
            this.$fetch({
                url: '/esf-ajax/property/home/mid/ad'
            }).then(res => {
                this.newData = res;
            });
        }
    }
};
</script>

<style>
.gifx {
    display: block;
    width: 100%;
    max-height: 150px;
    overflow: hidden;
}
.gifx img {
    display: block;
    width: 100%;
    max-height: 150px;
    height: auto;
}
.img-new {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
</style>
