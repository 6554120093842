// import Appdownload from './plugins/app-download';
import Appdownload from '@ajk/touch-vue-components/src/plugins/app-download';

const download = new Appdownload();
export default {
    directives: {
        /**
         * v-appdownload Object
         *  {
         *    openapp: Boolen default:true 是否打开APP
         *    pm: String 包号
         *  }
         */
        appdownload: {
            inserted:function(el,binding){
                if(binding.value && !binding.value.isfromApp){
                    download.clickDownload(el,binding);
                }
            }
            //inserted:download.clickDownload.bind(download),
        },
        appinopen: {
            inserted:download.clickDownload.bind(download),
        },
    }
}
