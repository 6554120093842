<template>
    <div
        class="down house-item fix-clear dl-house-item"
        :class="{ 'no-border': !hasBorder }"
        v-appdownload="{ pm: data.pm }"
        v-data-npv="data.clickSoj"
    >
        <div class="dl-house-item-wrap">
            <div class="house-img-wrap">
                <img src="//pages.anjukestatic.com/usersite/touch/img/appdownload/esf.png" alt="" class="house-img" />
            </div>
            <div class="down-intro">
                <div class="down-title down-intro-item">{{ data.month + '月' + data.cityName }}优惠房源</div>
                <div class="down-desc down-intro-item">实时更新</div>
                <div class="down-desc down-intro-item">尽在安居客APP</div>
            </div>

            <!-- 下载按钮状态 -->
            <div class="down-button">立即打开</div>
        </div>
        <section v-if="tip" class="dl-version">
            <span>应用版本：v{{ tip.version }}</span>
            <i class="dl-version-separate"></i>
            <span>开发者：{{ tip.developer }}</span>
            <i class="dl-version-separate headdler-version-separate-left"></i>
            <span>更新时间：{{ tip.updateTime }}</span>
            <i class="dl-version-separate"></i>
            <span @click.prevent.stop="onPrivateClick" class="i">隐私政策</span>
            <i class="dl-version-separate"></i>
            <span @click.prevent.stop="onEvent('onPermissionClick', {})" class="i">申请权限</span>
            <i class="dl-version-separate"></i>
            <span @click.prevent.stop="onProductClick" class="i">产品功能</span>
        </section>
    </div>
</template>

<script>
import appdown from 'oldCommon/mixins/DownloadAndOpenApp.js';

export default {
    inject: ['ENV'],
    mixins: [appdown],
    name: 'promotionRecommend',
    props: {
        data: {
            required: true,
            type: Object
        },
        hasBorder: {
            type: Boolean,
            default: true
        },
        tip: {
            type: Object,
            default: null
        }
    },
    methods: {
        onEvent(name, config) {
            this.$emit(name, config);
        },
        onPrivateClick() {
            this.$jump('https://m.anjuke.com/policy/privacy?external=true', {}, true);
        },
        onProductClick(){
            this.$jump(this.ENV === 'anjuke' ? 'https://m.anjuke.com/feuser/richcontent/agreement?id=112' : 'https://static.58.com/git/_fe/ershoufang/static-pages/esf/common/index.html', {}, true)
        },
    }
};
</script>

<style scoped lang="scss" src="./index.scss"></style>
<style scoped lang="scss">
.dl-house-item {
    display: block;
    padding-bottom: 6px !important;
}
.dl-house-item-wrap {
    display: -webkit-box;
}
.house-item.down {
    padding-right: 15px;
    padding-bottom: 15px;
    .down-button {
        right: 15px;
    }
}

.dl-version {
    padding: 4px 0;
    background-color: #fff;
    font-size: 0;

    > span {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        line-height: 16px;
        color: #979b9e;
        vertical-align: middle;
    }

    > span.i {
        vertical-align: middle;
        text-decoration: underline;
    }

    &-separate {
        display: inline-block;
        width: 1px;
        height: 8px;
        vertical-align: middle;
        background-color: #979b9e;
        margin: 0 4px;
    }
}
</style>
