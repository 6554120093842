<template>
    <div v-show="isShow" class="msgtips">
        <div class="msg_content">
            <h4 class="application_title">敏感隐私权限用途说明：</h4>
            <p class="application_text">1. 允许应用基于基站、 Wi-Fi 等网络源获取位置信息。</p>
            <p class="application_text">2. 允许应用录制音频。</p>
            <p class="application_text">3. 显示弹框、全屏界面到其他应用上面。</p>
            <p class="application_text">4. 允许应用修改或删除存储卡上的照片、媒体内容和文件。</p>
            <p class="application_text">5. 允许应用基于GPS、基站、 Wi-Fi 等网络源获取位置信息。这可能会增加耗电量。</p>
            <p class="application_text">6. 允许应用拍摄照片和视频。</p>
            <p class="application_text">7. 允许应用读取存储卡上的照片、媒体内容和文件。</p>
            <p class="application_text">8. 允许应用获取本机号码、通话状态以及拨打的号码。</p>
            <p class="application_text">9. 修改设置应用中的设置项。</p>
            <div class="clearfix">
                <button @click="onCloseClick" class="close_tip_ql">已知晓</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        isShow: {
            default: false,
            type: Boolean
        }
    },
    methods: {
        onCloseClick() {
            this.$emit('onCloseClick');
        }
    }
};
</script>
<style lang="scss" scoped>
.msgtips {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
}

.msg_content {
    position: absolute;
    color: #333;
    background: #fff;
    border-radius: 4px;
    text-align: left;
    padding: 20px 0 5px;
    width: 80%;
    -webkit-border-radius: 4px;
    margin: auto;
    font-size: 14px;
    padding: 2%;
    top: 20%;
    left: 8%;
}

.application_title {
    font-weight: bold;
    color: #333;
    font-size: 14px;
    display: block;
    line-height: 20px;
}

.application_text {
    color: #333;
    font-size: 14px;
    display: block;
    line-height: 20px;
}

.clearfix {
    text-align: center;
    margin-top: 10px;

    &::after {
        content: ' ';
        clear: both;
        display: block;
        height: 0;
        overflow: hidden;
        line-height: 0;
        visibility: hidden;
    }
}

.close_tip_ql {
    background-color: #dedede;
    padding: 2px 8px;
    border-radius: 4px;
    border: 1px solid #999;
    outline: 0;
}
</style>
