/**
 * 给函数加延迟执行,第一次跟最后一次都执行
 * @param func
 * @param wait
 * @returns {Function}
 */
import _ from 'lodash'

export function throttle(func, wait=50) {
    let timeout,
        context,
        args,
        previous = 0;

    let later = function () {
        timeout = null;
        func.apply(context, args);
    }
    return function () {
        context = this;
        args = arguments;

        let now = Date.now();
        let remaining = (now - previous) - wait;
        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            previous = now;
            func.apply(context, args);
        } else {
            timeout = setTimeout(later, remaining);
        }
    }
}

/**
 * 将
 * @param jsonStr
 */
let platform = ['wxhy', 'pyq', 'sinawb', 'msg'] //安居客分享类型
let sharetoArr = ["WEIXIN", "FRIENDS", "SINA", "", 'QQ'] //58分享类型

function getShareTypeAnjukeInWuba(type) {
    let index = platform.indexOf(type)
    if (index > -1 && sharetoArr[index]) {
        return sharetoArr[index]
    }
    return false
}

export function shareContentAnjukeToWuba(jsonStr) {
    let jsonObj = null
    let res = []
    try {
        jsonObj = typeof jsonStr === 'string' ? JSON.parse(jsonStr) : jsonStr
        _.forEach(jsonObj, (value) => {
            let temp = {}
            let shareto = getShareTypeAnjukeInWuba(value.pm)
            if (shareto) {
                let {url, title, description: content, image: img_url} = value
                res.push({
                    shareto, url, title, content, img_url
                })
            }
        })
        return res.length ? res : null
    } catch (e) {
        return false
    }
}

/**
 * 判断是否为普通的url
 * @param url
 */
export function isCommonUrl(url) {
    return /^http/.test(url) || /^(\/){1,2}[a-zA-z0-9]+/.test(url)
}

export function objToParams(obj) {
    if (!_.isPlainObject(obj) || _.isEmpty(obj)) {
        return ''
    }
    let params = []
    for (let key in obj) {
        params.push(`${key}=${obj[key]}`)
    }
    return params.join('&')
}

/**
 * 检查是不是58host
 * @param origin
 * @returns {boolean}
 */
export function checkIsWubaHost({origin=""}={}) {
    return origin.indexOf('.58.com') > -1
}

export function getUrlParameter (name) {
  if (name) {
    var pattern = "(^|&)" + name + "=([^&]*)(&|$)";
    var flags = "i"; // 大小写不记
    var reg = new RegExp(pattern, flags); //构造一个含有目标参数的正则表达式对象
    var result = window.location.search.substr(1).match(reg);  //匹配目标参数
    if (result) return decodeURIComponent(result[2]);
  }
  return null; //返回参数值
}

export function parseQueryString(url) {
  if (!url || !_.isString(url)) {
    console.log(`parseQueryString error:参数类型不符`);
    return {}
  }
  var obj = {};
  var keyvalue = [];
  var key = "",
    value = "";
  var paraString = url.substring(url.indexOf("?") + 1, url.length).split("&");
  for (var i in paraString) {
    keyvalue = paraString[i].split("=");
    key = keyvalue[0];
    value = keyvalue[1];
    obj[key] = value;
  }
  return obj;
}

/**
 * Vr样板间环境判断  仅适用于客户端
 * @returns {number|*}
 */
export function getWVRChatConfig() {
  if (typeof window === 'undefined') {
    return 0;
  }
  let wvConfig = null;
  // 安卓原生
  if (window.WVRChatNativeFunction) {
    console.log('Android');
    wvConfig = JSON.parse(window.WVRChatNativeFunction.getWVRChatConfig());
  }
  // ios
  if (window.webkit && window.getWVRChatConfig) {
    wvConfig = JSON.parse(window.getWVRChatConfig());
  }
  if (_.isPlainObject(wvConfig)) {
    console.log(`wvConfig:`, wvConfig);
    // 参考地址 问题4 http://wbook.58corp.com/books/wvrchat/sdk-client/vr-Android/importv2.html#question
    // 数据格式，json字符串，根据scene获取场景信息，全景：panoramic  带看：vr_chat， 场景信息会动态更新（2.0调整）。
    /* {
            "sdk_version":"1.1.0_tmp",
            "scene":"panoramic",
            "os_version":"9",
            "device_type":"android",
            "device_brand":"HONOR",
            "device_mode":"BKL-AL20"
        } */
    const { sdk_version: sdkVersion } = wvConfig;
    return sdkVersion ? sdkVersion.split('.')[0] : 0;
  }
  return 0;
}

