/**
 * 日志
 * @param pageName
 * @param level
 * @param info
 */
//1 error 2 warn 3 info
const MIN_LEVEL = 1
const MAX_LEVEL = 3
//Anjuke_Home 大首页
const outPutPage = []
let ALERT_REPLACE_CONSOLE = false

export function printLog(pageName, level, info) {
    // return false //正式环境不用开启log输出
    if (outPutPage.indexOf(pageName) === -1) {
        return false
    }
    try {
        level = parseInt(level)
        level = level < MIN_LEVEL ? MIN_LEVEL : (level > MAX_LEVEL ? MAX_LEVEL : level)
    } catch (e) {
        level = 1
    }
    let printInfo = Array.prototype.slice.call(arguments, 2)
    let color = '#000000'
    let infoTitle = 'INFO'
    switch (level) {
        case 1:
            color = '#ff0000'
            infoTitle = 'ERROR'
            break
        case 2:
            color = '#FFCC00'
            infoTitle = 'WARN'
            break
    }
    if (!ALERT_REPLACE_CONSOLE) {
        if (typeof window !== 'undefined') {
            console.log(`%c${pageName}[${infoTitle}]:`, `color:${color}`, ...printInfo)
        } else {
            console.log(`${pageName}[${infoTitle}]:`, ...printInfo)
        }
    } else {
        _.forEach(printInfo, function (value, index) {
            try {
                alert(JSON.stringify(value))
            } catch (e) {
                alert('不能被json.stringify')
            }
        })
    }


}
