import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
    props: {
        item: {
            required: true,
            type: Object
        },
        fromSoj: {
            type: String,
            default: ''
        },
        hasBorder: {
            type: Boolean,
            default: true
        },
        iconDesc: {
            type: String,
            default: '真实在售'
        },
        showBroker: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            saleList: []
        };
    },
    computed: {
        ...mapGetters({
            request: 'getRequest'
        }),
        isFromApp() {
            const app = this.request.isFromApp;
            if (app === 'i-broker2' || app === 'a-broker') {
                return false;
            }
            return app;
        },
        isHasTags() {
            return this.item.is_guarantee || (this.item.tags && this.item.tags.length);
        },
        item_href() {
            const { isFromApp, item } = this;
            const { app_url, url, jump_action } = item;
            if (jump_action) return jump_action; // for 小欢喜活动
            return isFromApp ? app_url || url : url;
        },
        showUnit() {
            const price = this.item.price;
            return _.isNumber(price);
        }
    },

    created() {},

    methods: {
        clickCell() {
            this.clickEvent();
            setTimeout(() => {
                locations.href = this.item.url;
            }, 300);
        }
    }
};
