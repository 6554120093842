<template>
    <a class="question-item" :class="{'no-border':!hasBorder}" :href="question.url" :data-from="question.qaFromSoj">
        <div class="title"><i>问</i><span>{{question.title}}</span></div>
        <div class="res-u" v-if="question.ask_user&&question.ask_user.nick_name">
            <div class="res-u-photo"
                 v-lazy:background-image="question.ask_user&&question.ask_user.user_photo || defaultQaPhoto">
            </div>
            <p class="res-u-name" v-if="question.ask_user.nick_name">{{question.ask_user.nick_name+" 的回答"}}</p>
        </div>
        <div class="res-r" v-if="question.best_answer&&question.best_answer.content">{{question.best_answer.content}}
        </div>
        <div class="res-r" v-else>暂无回答</div>

        <div class="bot">
            <div class="classify-list" v-if="isHasClassify">
                <div class="classify"
                     v-for="(value,index) in question.classify_infos.slice(0,3)"
                     @click.stop.prevent="goToTagUrl(value.url,question.tagFromSoj)"
                     :key="index">
                    <!--{{value.name}}<i class="icon-right-css"></i>-->
                    {{value.name || " "}}<i class="icon icon-sy_wd_icon_next"></i>
                </div>
            </div>
            <div class="classify-list" v-else></div>
            <div class="answer-number"><span>{{question.answer_count || "0"}}</span>个回答</div>
        </div>
    </a>
</template>

<script>
    import _ from 'lodash'

    export default {
        name: "hotQuestion",
        data() {
            return {
                defaultQaPhoto: 'https://pic1.ajkimg.com/display/anjuke/88732a68a38f6f536276d6284caaacaa/108x108c.jpg',
            }
        },
        computed: {
            isHasClassify() {
                return _.isArray(this.question.classify_infos) && this.question.classify_infos.length
            }
        },
        methods: {
            goToTagUrl(url, from) {
                if (from) {
                    url += (url.indexOf('?') > -1 ? '&' : '?') + 'from=' + from
                }
                locations.href = url
            }
        },
        props: {
            question: {
                type: Object,
                required: true
            },
            hasBorder: {
                type: Boolean,
                default: true
            }
        }
    }
</script>

<style scoped lang="scss">
    .question-item {
        position: relative;
        margin-left: 15px;
        padding: 15px 15px 15px 0;
        display: block;
        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            border-bottom: 1px solid #E6E6E6;
            transform: scaleY(0.5);
        }
        &.no-border::after {
            display: none;
        }
        .title {
            position: relative;
            i {
                position: absolute;
                float: left;
                left: 0;
                top: 2px;
                width: 18px;
                height: 18px;
                font-size: 12px;
                line-height: 18px;
                color: #FFFFFF;
                text-indent: 0;
                text-align: center;
                background: #3CB950;
            }
            span {
                font-size: 17px;
                color: #1F2326;
                line-height: 23px;
                font-weight: bold;
                word-break: break-all;
                text-indent: 22px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
            }
        }
        .res-u {
            display: flex;
            align-items: center;
            padding-top: 10px;
            height: 18px;
            overflow: hidden;
            .res-u-photo {
                margin-right: 5px;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                overflow: hidden;
                background-position: center;
                background-size: cover;
            }
            .res-u-name {
                font-size: 12px;
                color: #676C73;
                line-height: 12px;
            }

        }
        .res-r {
            padding-top: 10px;
            font-size: 13px;
            color: #676C73;
            line-height: 19px;
            word-break: break-all;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
        .bot {
            display: flex;
            margin-top: 15px;
            justify-content: space-between;
            align-items: center;

            .classify-list {
                flex: 1;
                height: 20px;
                overflow: hidden;
                .classify {
                    display: block;
                    position: relative;
                    float: left;
                    margin-left: 10px;
                    padding: 4px 15px 4px 6px;
                    font-size: 12px;
                    color: #676C73;
                    letter-spacing: 0;
                    line-height: 12px;
                    background: #F3F3F4;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 100%;
                    &:first-child {
                        margin-left: 0;
                    }
                    .icon {
                        position: absolute;
                        right: 5px;
                        top: 50%;
                        margin-top: -3px;
                        font-size: 12px;
                        transform: scale(0.5) translateY(-50%);
                        transform-origin: right center;
                        &::after {
                            font-size: 12px;
                        }
                    }
                    .icon-right-css {
                        position: absolute;
                        right: 5px;
                        top: 50%;
                        width: 0;
                        height: 0;
                        margin-top: -2px;
                        border-width: 0 4px 4px;
                        border-style: solid;
                        border-color: transparent transparent #676C73;
                        transform: rotate(90deg) translateY(-50%) scale(0.8);

                        &::after {
                            content: "";
                            position: absolute;
                            top: 1px;
                            left: -3px;
                            border-width: 0 3px 3px;
                            border-style: solid;
                            border-color: transparent transparent #F3F3F4;
                        }
                    }
                }
            }
            .answer-number {
                margin-left: 10px;
                font-size: 11px;
                color: #666666;
                line-height: 13px;
                vertical-align: baseline;
                span {
                    display: inline-block;
                    font-size: 13px;
                    vertical-align: inherit;
                }
            }
        }
    }
</style>
