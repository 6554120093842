const isTest = process.env.NODE_ENV !== 'production';
const staticUrl = 'https://pages.anjukestatic.com/usersite';
// const staticUrlTest = 'https://userpages.anjuke.test/usersite';
// const getStaticUrl = url => (isTest ? staticUrlTest + url : staticUrl + url);
const getStaticUrl = url => (isTest ? staticUrl + url : staticUrl + url);

/**
 * 是否在小程序中(微信、支付宝、百度、抖音)
 * @param userAgent
 * @returns {boolean}
 */
function isMiniProgram(userAgent) {
    if (typeof userAgent !== 'string') {
        return false;
    }
    const ua = userAgent.toLowerCase();
    return (
        // 微信、支付宝、快手
        ua.includes('miniprogram') ||
        // 抖音
        ua.includes('toutiaomicroapp') ||
        // 百度
        ua.includes('swan-baiduboxapp')
    );
}

function getAppEnv(userAgent = '', domain = '') {
    const env = {
        isMiniProgram: false,
        isQuickApp: false,
        app: 'unknown',
        platform: domain.includes('anjuke.com') ? 'anjuke' : domain.includes('58.com') ? 'wuba' : 'unknown'
    };
    if (typeof userAgent === 'string') {
        const ua = userAgent.toLowerCase();
        env.isMiniProgram = isMiniProgram(ua);
        env.isQuickApp = /hap/.test(ua);
        if (ua.includes('micromessenger')) {
            env.app = 'weapp';
        } else if (ua.includes('toutiao')) {
            env.app = 'tt';
        } else if (ua.includes('swan')) {
            env.app = 'swan';
        } else if (ua.includes('alipay')) {
            env.app = 'alipay';
        } else if (ua.includes('kuaishou')) {
            env.app = 'ks';
        } else if (ua.match(/ajk\/[0-9a-z-.]*/g)) {
            env.app = 'ajk';
        } else if (ua.includes('ajk-broker-')) {
            env.app = 'ajk-broker';
        } else if (ua.match(/wuba\/[0-9a-z-.]*/g)) {
            env.app = 'wb';
        }
    }
    return env;
}

export { getStaticUrl, getAppEnv };
