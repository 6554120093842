export function buildURL({ city, page, domain, params, query }) {
    let url = '';
    switch (page) {
        case 'saleList':
            url = `/sale/`;
            break;
        case 'qalist':
            url = `/ask/all/`;
            break;
        case 'xfList':
            url = `/xinfang/`;
            break;
    }
    if (url) {
        url = (city ? `/${city}` : '') + url;
        url = (domain ? domain : '') + url;
        return url;
    } else {
        return '';
    }
}
