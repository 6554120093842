/*
export const ModalHelper = ((bodyCls) => {
    let scrollTop;
    return {
        afterOpen: function () {
            let doc = document.scrollingElement || document.body || document.documentElement;
            scrollTop = doc.scrollTop;
            document.body.classList.add(bodyCls);
            document.body.style.top = -scrollTop + 'px';
        },
        beforeClose: function () {
            let doc = document.scrollingElement || document.body || document.documentElement;
            document.body.classList.remove(bodyCls);
            // scrollTop lost after set position:fixed, restore it back.
            doc.scrollTop = scrollTop;
            document.body.style.top = '';
        }
    };
})('dialog-open');
*/
export const ModalHelper = (bodyCls => {
  let scrollTop;
  let len = 0;
  const afterOpenCallback = {};
  const beforeCloseCallback = {};
  return {
    afterOpen() {
      const doc = document.scrollingElement || document.body || document.documentElement;
      len++;
      if (!document.body.classList.contains(bodyCls)) {
        scrollTop = doc.scrollTop;
        document.body.classList.add(bodyCls);
        document.body.style.top = -scrollTop + 'px';
      }
      Object.keys(afterOpenCallback).forEach(value => {
        if (typeof afterOpenCallback === 'function') {
          afterOpenCallback[value](len);
        }
      });
    },
    afterOpenCallback,
    beforeClose() {
      len--;
      Object.keys(beforeCloseCallback).forEach(value => {
        if (typeof beforeCloseCallback === 'function') {
          beforeCloseCallback[value](len);
        }
      });
      const doc = document.scrollingElement || document.body || document.documentElement;
      if (len < 1) {
        len = 0;
        if (document.body.classList.contains(bodyCls)) {
          document.body.classList.remove(bodyCls);
          // scrollTop lost after set position:fixed, restore it back.
          doc.scrollTop = scrollTop;
          document.body.style.top = '';
        }
      }
    },
    beforeCloseCallback
  };
})('dialog-open');
