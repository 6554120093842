<template>
    <dl class="seo-footer" id="seo-footer">
        <template v-for="value in seoList">
            <!--子项可以展开-->
            <template v-if="value.style_type===1">
                <dt>{{value.name}}<i class="g-base-arrow"></i></dt>
                <dd class="clearfix regions"><!--id="seo-regionlist"-->
                    <a v-for="(item,index) in value.list" :data-id="index" href="javascript:;">{{item.title}}</a>
                </dd>
                <template v-for="(val,inx) in value.list">
                    <dd class="clearfix list seo-regionlist" :id="'seo-regionlist-'+inx">
                        <a class="g-overflow" v-for="v in value.list[inx]['list']" :href="v.url">{{v.title}}</a>
                    </dd>
                </template>
            </template>
            <!--两列-->
            <template v-else-if="value.style_type===2">
                <dt>{{value.name}}<i class="g-base-arrow"></i></dt>
                <dd class="clearfix list">
                    <a class="g-overflow" v-for="item in value.list" :href="item.url">{{item.title}}</a>
                </dd>
            </template>
            <!--快速翻页-->
            <template v-else-if="value.style_type===3&&value.list&&value.list.length">
                <!--<dt>{{value.name}}<i class="g-base-arrow"></i></dt>
                <dd class="clearfix list">
                <a class="g-overflow" v-for="item in value.list" :href="item.url">{{item.title}}</a>
                </dd>-->
                <dt>快速翻页<i class="g-base-arrow"></i></dt>
                <dd class="clearfix go-page">
                    <p class="row" v-for="v in Math.ceil(value.list.length/4)">
                        <template v-for=" j in 4">
                            <a v-if='4*(v-1)+j-1<value.list.length'
                               :href="value.list[4*(v-1)+j-1]['url']">{{value.list[4*(v-1)+j-1]['title']}}</a>
                        </template>
                    </p>
                    <!--<a class="g-overflow" v-for="item in value.list" :href="item.url">{{item.title}}</a>
                    <p class="row">
                        <a class="g-overflow" href="">demo1</a>
                        <a class="g-overflow" href="">demo2</a>
                        <a class="g-overflow" href="">demo3</a>
                        <a class="g-overflow" href="">demo4</a>
                        <a class="g-overflow" href="">demo5</a>
                        <a class="g-overflow" href="">demo5</a>
                    </p>
                    <p class="row">
                        <a class="g-overflow" href="">demo1</a>
                        <a class="g-overflow" href="">demo2</a>
                        <a class="g-overflow" href="">demo3</a>
                        <a class="g-overflow" href="">demo4</a>
                        <a class="g-overflow" href="">demo5</a>
                    </p>-->
                </dd>
            </template>
        </template>
        <!--<dt>快速翻页<i class="g-base-arrow"></i></dt>
        <dd class="clearfix go-page">
            <p class="row">
                <a class="g-overflow" href="">demo1</a>
                <a class="g-overflow" href="">demo2</a>
                <a class="g-overflow" href="">demo3</a>
                <a class="g-overflow" href="">demo4</a>
                <a class="g-overflow" href="">demo5</a>
                <a class="g-overflow" href="">demo5</a>
            </p>
            <p class="row">
                <a class="g-overflow" href="">demo1</a>
                <a class="g-overflow" href="">demo2</a>
                <a class="g-overflow" href="">demo3</a>
                <a class="g-overflow" href="">demo4</a>
                <a class="g-overflow" href="">demo5</a>
            </p>
        </dd>
        <dt>频道导航<i class="g-base-arrow"></i></dt>
        <dd class="clearfix list">
            <a class="g-overflow" v-for="value in 5" href="#">导航{{value}}</a>
        </dd>
        <dt>大同各区域二手房<i class="g-base-arrow"></i></dt>
        <dd class="clearfix regions" id="seo-regionlist">
            &lt;!&ndash;data-id是区域i&ndash;&gt;
            <a data-id="0" href="javascript:;">天镇</a>
            <a data-id="1" href="javascript:;">广灵</a>
            <a data-id="2" href="javascript:;">灵丘</a>
            <a data-id="3" href="javascript:;">大同</a>
            <a data-id="4" href="javascript:;">阳高</a>
            <a data-id="5" href="javascript:;">左云</a>
            <a data-id="6" href="javascript:;">浑源</a>
            <a data-id="7" href="javascript:;">城区</a>
            <a data-id="8" href="javascript:;">南郊</a>
            <a data-id="9" href="javascript:;">矿区</a>
            <a data-id="10" href="javascript:;">新荣</a>
        </dd>-->


    </dl>
</template>

<script>
    export default {
        name: "seoFooter",
        props: {
            cityName: {
                type: String
            },
            seoInfo: {
                type: Array,
                required: true
            }
        },
        computed: {
            seoList() {

                return this.seoInfo.map(v => {
                    if (v.classify) {
                        v.name = this.typeToName(v.classify)
                    } else if (v.title) {
                        v.name = v.title
                    }
                    v.style_type = v.style_type || 2
                    return v
                })
            }
        },
        mounted() {
            this.addEvent()
        },
        methods: {
            typeToName(type) {
                let name = "";
                switch (type) {
                    case "channel_nav":
                        name = "频道导航";
                        break;
                    case "region_list":
                        name = (this.cityName || "") + "各区域二手房";
                        break;
                    case "hot_property_link":
                        name = "推荐房产网";
                        break;
                    case "new_hot_property_link":
                        name = "热门房产网";
                        break;
                    case "hot_loupan_link":
                        name = "热门楼盘";
                        break;
                    case "city_shouye_hot_property":
                        name = "热门二手房";
                        break;
                    case "city_bieshu":
                        name = "城市别墅";
                        break;
                }
                return name
            },
            addEvent() {
                var footer = $('#seo-footer');
                footer.on('click', 'dt', function (event) {
                    var self = $(this),
                        //parent = self.parent('dt'),
                        next = self.next();
                    if (self.hasClass('open')) {
                        next.hide();

                        // 隐藏区域子版块
                        next = next.next('dd');
                        while (next.length > 0) {
                            next.hide();
                            next = next.next('dd');
                        }
                    } else {
                        next.show();
                    }
                    $(this).toggleClass('open');
                });
                footer.find('dt').each(function (index, el) {
                    var next = $(el).next();
                    if (next.next('dd').length === 0) {
                        return;
                    }
                    next.removeClass('list').addClass('regions');
                    next.on('click', 'a', function (event) {
                        event.preventDefault();
                        event.stopPropagation();
                        var self = $(this),
                            i = 0,
                            nextdd,
                            index = self.index(),
                            parent = self.parent();
                        nextdd = parent.next('dd');
                        while (true) {
                            if (i === index) {
                                nextdd.show();
                            } else {
                                nextdd.hide();
                            }
                            nextdd = nextdd.next('dd');
                            if (nextdd.length === 0) {
                                break;
                            }
                            i++;
                        }
                    });
                });
            }
        }
    }
</script>

<style scoped>
    .seo-footer {
        margin: 10px 0;
        background: #fff;
        font-size: 1.4rem;
    }

    .seo-footer dt {
        position: relative;
        height: 40px;
        line-height: 40px;
        padding: 0 15px;
        font-size: 1.4rem;
        border-bottom: 1px solid #e8e8e8;
    }

    .seo-footer dt a {
        display: block;
        height: 100%;
        color: #333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 20px;
    }

    .seo-footer dt i {
        display: -webkit-box;
        -webkit-box-align: center;
        -webkit-box-pack: center;
        position: absolute;
        right: 0;
        top: 0;
        height: 40px;
        width: 40px;
    }

    .seo-footer dt i:after {
        margin-top: -4px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .seo-footer dt .open:after {
        margin-top: 4px;
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
    }

    .seo-footer dd {
        display: none;
        border-bottom: 1px solid #e8e8e8;
    }

    .seo-footer .list a {
        float: left;
        width: 50%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        padding: 0 10px;
        color: #333;
        border: 1px dashed #e8e8e8;
        border-width: 0 1px 1px 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    .seo-footer .regions {
        padding: 0 15px;
    }

    .seo-footer .regions a {
        float: left;
        margin: 12px 3px 11px 0;
        padding: 0 10px;
        height: 21px;
        line-height: 21px;
        color: #333;
        text-align: center;
    }

    .seo-footer .list a:nth-last-child(2) {
        border-bottom: 0;
    }

    .seo-footer .list a:nth-last-child(1) {
        border: 0;
    }

    .seo-footer dt:last-of-type {
        border-bottom: 0;
    }

    .seo-footer dd:last-of-type {
        border-top: 1px solid #e8e8e8;
        border-bottom: 0;
    }

    /*翻页样式*/
    .row {
        display: flex;
        display: -webkit-flex;
        height: 43px;
        line-height: 43px;
        border-bottom: #e5e5e5 solid 1px;
        -webkit-box-pack: center;
        -webkit-box-align: center;
    }

    .row:last-of-type {
        border-bottom: none;
    }

    .row a {
        flex: 1;
        -webkit-flex: 1;
        text-align: center;
        font-size: 1.4rem;
        color: #333;
        border-left: #e5e5e5 solid 1px;
    }

    .row a:focus {
        color: #62ab00;
    }

    /*按压效果*/
    .g-press:active {
        background-color: #f4f4f4;
    }

    .g-overflow {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .g-next {
        position: relative;
    }

    .g-base-arrow:after,
    .g-next:after {
        display: block;
        content: '';
        height: 7px;
        width: 7px;
        border: 2px solid #ccc;
        border-width: 0 1px 1px 0;
    }

    /*箭头:>*/
    .g-next:after {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 2px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    .clearfix:after {
        display: block;
        height: 0;
        line-height: 0;
        content: '';
        clear: both;
        visibility: hidden;
    }
</style>
