<template>
    <div class="house-price-wrap" v-data-trace="houseprice.exposure" :data-from="houseprice.from"
        @click.prevent.stop="onEvent('onPriceClick', {jump_action: houseprice.jump_action})">
        <img class="back-icon" src="https://pages.anjukestatic.com/fe/esf/img/32a9f2c0/back.png">
        <div class="lt">
            <img class="title" src="https://pages.anjukestatic.com/fe/esf/img/b4107258/title.png">
            <p class="tag">{{cardInfo.city_name}}房价</p>
        </div>
        <div class="rt">
            <template v-if="!!esfPrice && !!xfPrice">
                <div class="item-wrap" style="margin-right:30px">
                    <div class="item">
                        <p class="price"><span class="price-num">{{esfPriceData.price}}</span><span
                                class="price-unit">元/㎡</span></p>
                        <br/>
                        <p class="info">
                            <span class="info-name">二手房</span>
                            <span v-if="esfPriceData.month_change && esfPriceData.esf_status!=='equal'"
                                  class="range" :class="['range-'+esfPriceData.esf_status]">
                                <img class="icon" :class="['icon-'+esfPriceData.esf_status]" :src="esfPriceData.esf_status === 'up' ? 'https://pages.anjukestatic.com/fe/esf/img/2188bc55/up.png' : 'https://pages.anjukestatic.com/fe/esf/img/0cfe18ce/down.png'" />
                                {{Math.abs(esfPriceData.month_change)}}%
                            </span>
                            <span class="range equal" v-if="esfPriceData.esf_status==='equal'">持平</span>
                        </p>
                    </div>
                </div>
                <div class="item-wrap">
                    <div class="item">
                        <p class="price"><span class="price-num">{{xfPriceData.price}}</span><span
                                class="price-unit">元/㎡</span></p>
                        <br/>
                        <p class="info">
                            <span class="info-name">新房</span>
                            <span v-if="xfPriceData.month_change && xfPriceData.xf_status!=='equal'"
                                  class="range" :class="['range-'+xfPriceData.xf_status]">
                                <img class="icon" :class="['icon-'+xfPriceData.xf_status]" :src="xfPriceData.xf_status === 'up' ? 'https://pages.anjukestatic.com/fe/esf/img/2188bc55/up.png' : 'https://pages.anjukestatic.com/fe/esf/img/0cfe18ce/down.png'" />
                                {{Math.abs(xfPriceData.month_change)}}%
                            </span>
                            <span class="range equal" v-if="xfPriceData.xf_status==='equal'">持平</span>
                        </p>
                    </div>
                </div>
            </template>
            <template v-if="(!!esfPrice && !xfPrice) || (!esfPrice && !!xfPrice)">
                <div class="item-wrap" style="margin-right:30px">
                    <div class="item">
                        <p class="price"><span class="price-num">{{!!esfPrice ? esfPrice : xfPrice}}</span><span
                                class="price-unit">元/㎡</span></p>
                        <br/>
                        <p class="info">
                            <span class="info-name">最新均价</span>
                        </p>
                    </div>
                </div>
               <div class="item-wrap esf">
                   <div class="item">
                       <p class="price" v-if="!!esfPrice && !xfPrice">
                            <span class="range">
                                <img v-if="esfPriceData.month_change && esfPriceData.esf_status!=='equal'"
                                    class="icon" :class="['icon-'+esfPriceData.esf_status]" :src="esfPriceData.esf_status === 'up' ? 'https://pages.anjukestatic.com/fe/esf/img/2188bc55/up.png' : 'https://pages.anjukestatic.com/fe/esf/img/0cfe18ce/down.png'" />
                                {{Math.abs(esfPriceData.month_change) || '-' }}
                            </span>
                            <span class="precent-sign" v-if="esfPriceData.month_change && esfPriceData.esf_status!=='equal'">%</span>
                            <span class="range equal" v-if="esfPriceData.esf_status==='equal'">持平</span>
                        </p>
                        <p class="price" v-else>
                            <span class="range">
                                <img v-if="xfPriceData.month_change && xfPriceData.xf_status!=='equal'"
                                    class="icon" :class="['icon-'+xfPriceData.xf_status]" :src="xfPriceData.xf_status === 'up' ? 'https://pages.anjukestatic.com/fe/esf/img/2188bc55/up.png' : 'https://pages.anjukestatic.com/fe/esf/img/0cfe18ce/down.png'" />
                                {{Math.abs(xfPriceData.month_change) || '-' }}
                            </span>
                            <span class="precent-sign" v-if="xfPriceData.month_change && xfPriceData.xf_status!=='equal'">%</span>
                            <span class="range equal" v-if="xfPriceData.xf_status==='equal'">持平</span>
                        </p>
                       <br/>
                       <p class="info">
                           <span class="info-name">比上月</span>
                       </p>
                   </div>
               </div>
            </template>
        </div>
    </div>
</template>

<script>
    import {printLog} from "../../function/log"
    import _ from 'lodash'

    export default {
        name: "houseprice",
        props: {
            houseprice: {
                required: true,
                type: Object
            }
        },
        data() {
            return {
                cardInfo: this.houseprice.card_info,
            }
        },
        mounted() {
            this.printLog(3, "props:", this.houseprice.price)
        },
        computed: {
            esfPriceData() {
                let esf_price_data = _.get(this.cardInfo, "esf") || {};
                let arr = _.cloneDeep(esf_price_data);
                let esf_range = _.get(esf_price_data, "month_change");
                arr.esf_status = +esf_range === 0 ? "equal" : (+esf_range > 0 ? "up" : "down");
               
                return arr;
            },
            xfPriceData() {
                let xf_price_data = _.get(this.cardInfo, "xf") || {};
                let arr = _.cloneDeep(xf_price_data);
                let xf_range = _.get(xf_price_data, "month_change");
                arr.xf_status = +xf_range === 0 ? "equal" : (+xf_range > 0 ? "up" : "down");
                return arr;
            },
            esfPrice() {
                return _.get(this.esfPriceData, "price");
            },
            xfPrice() {
                return _.get(this.xfPriceData, "price");
            },
        },
        methods: {
            printLog(level, args) {
                printLog("房价Component:", ...arguments)
            },
            onEvent(name, config) {
                this.$emit(name, config);
            },
        }
    }
</script>

<style scoped lang="scss">
    .house-price-wrap {
        position: relative;
        display: flex;
        /*align-items: stretch;*/
        padding: 16px 0;
        margin: 18px 15px 0 15px;
        background-color: rgba(233, 249, 244, 0.5);
        border: 1px solid rgba(35, 201, 147, 0.3);
        border-radius: 6px;
        .back-icon {
            position: absolute;
            width: 64px;
            height: 60px;
            right: 0px;
            top: 0px;
        }
        br{
            margin: 0;
            padding: 0;
            display: block;
            height: 0;
            line-height: 0;
        }
        span{
            display: inline-block;
        }
        .lt {
            position: relative;
            display: flex;
            // flex: 1;
            width: 30%;
            /*box-sizing: border-box;*/
            flex-direction: column;
            justify-content: center;
            &-wrap {
                width: 68.48px;
            }
            .title {
                width: auto;
                height: 18px;
                margin: 0 auto;
            }
            .tag {
                margin-top: 10px;
                text-align: center;
                // height: 16px;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 12px;
                font-weight: 400;
                white-space: nowrap;
                color: rgba(71, 75, 78, 1);
            }
            &::after {
                content: "";
                position: absolute;
                right: 0;
                top: 50%;
                width: 2px;
                height: 20px;
                background: #D3F4E9;
                // margin-right: 12px;
                transform: scaleX(0.5) translateY(-50%);
            }
        }
        .rt {
            display: flex;
            // flex: 2;
            width: 70%;
            justify-content: center;
            .item-wrap {
                // flex: 9;
                text-align: center;
                .item {
                    display: inline-block;
                    margin: 0 auto;
                    text-align: left;
                }
                .price, .info {
                   display: inline-block;
                }
                .price {
                    color: #0B0F12;
                    line-height: 1;
                    vertical-align: baseline;
                    .price-num {
                        font-size: 20px;
                        // transform: translateY(1px);
                        font-weight: 900;
                        vertical-align: inherit;
                    }
                    .price-unit {
                        font-size: 12px;
                        font-weight: 500;
                        vertical-align: inherit;
                    }
                }
                .info {
                    margin-top: 8px;
                    font-size: 0;
                    color: #979B9E;
                    line-height: 1;
                    /*vertical-align: middle;*/
                    .info-name {
                        display: inline-block;
                        font-size: 12px;
                        /*vertical-align: inherit;*/
                    }
                    .range {
                        display: inline-block;
                        margin-left: 3px;
                        font-size: 12px;
                        font-weight: 500;
                        &.equal{
                            margin-left: 6px;
                            font-size: 12px;
                        }
                        /*vertical-align: inherit;*/
                        .icon{
                            width: 6px;
                            height: 8px;
                        }
                        .icon:after{
                            display: block;
                            line-height: 1;
                        }
                    }
                    .range-up {
                        color: #FA4600;
                    }
                    .range-down {
                        color: #3CB950;
                    }
                }
                &.esf{
                    .price{
                        .range{
                            font-size: 20px;
                            color: #1F2326;
                            line-height: 20px;
                            font-weight: 900;
                            .icon{
                                margin-right: -4px;
                                width: 6px;
                                height: 8px;
                            }
                        }
                        .range-up {
                            color: #FA4600;
                        }
                        .raner-down {
                            color: #3CB950;
                        }
                        .range.equal{
                            font-size: 12px;
                        }
                        .precent-sign{
                            font-size: 12px;
                        }
                    }

                }

            }
        }
    }
</style>
